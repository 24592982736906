import { useNavigate } from "react-router-dom";
import { GiWallet } from "react-icons/gi";
import Buttons from "../Components/Buttons";
import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";
import { baseUrl } from "../constant";
import { jwtDecode } from "jwt-decode";
import ChartDataLabels from "chartjs-plugin-datalabels";
import toast, { Toaster } from 'react-hot-toast';
import { FcInfo } from "react-icons/fc";
import serviceFunctions from "../Services";
import Notiflix from "notiflix";

export default function SpinWheel() {

    const [userData, setUserdata] = useState({});
    const [token, setToken] = useState("");
    const [user_id, setUserId] = useState("");
    const [tapToSpin, setTapToSpin] = useState(false);


    useEffect(() => {

        Notiflix.Loading.circle();

        const interval = setTimeout(() => {

            try {

                let token = localStorage.getItem("token");
                setToken(token);
                const decoded = jwtDecode(token);

                if (decoded) {

                    let user_id = decoded.user_id;

                    if (user_id) {
                        setUserId(user_id);
                    } else {
                        // localStorage.removeItem("token");
                        // navigate("/home");
                    }
                }

            } catch (err) {
                console.log(err.message);

            }
        }, 10);

        return () => clearInterval(interval);

    }, []);

    const [prize, setPrize] = useState([]);
    const [prizesId, setprizesId] = useState(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [rotationCount, setRotationCount] = useState(1);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [showCountdown, setShowCountdown] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [targetRotation, setTargetRotation] = useState(0);
    const [prizeId, setPrizeId] = useState(66);
    const audioRef = useRef(new Audio("spinsound.m4a"));
    const [walletClickable, setWalletClickable] = useState(true);
    const [sound, setSound] = useState(true);
    const [availableSpin, setAvailableSpin] = useState('0');

    useEffect(() => {
        const mysound = localStorage.getItem('sound');
        setSound(mysound ? mysound : true);
    }, [])

    // console.log(sound)
    const handleVisibilityChange = () => {
        if (document.hidden) {
            audioRef.current.muted = true;
        } else {
            audioRef.current.muted = false;
            if (isAudioPlaying && sound) {
                audioRef.current.play()
            }
        }
    };

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [isAudioPlaying]);

    const startCountdown = (seconds) => {
        setCountdown(seconds);
        setShowCountdown(true);

        const interval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(interval);
            setCountdown(0);
            setShowCountdown(false);
            setBtnDisable(false);
        }, seconds * 1000);
        setBtnDisable(true);
    };

    useEffect(() => {
        setWalletClickable(!isSpinning);
    }, [isSpinning]);

    let keyframesStyle;

    function createKeyframes(name, steps) {
        keyframesStyle = document.createElement("style");
        keyframesStyle.innerHTML = `
          @keyframes ${name} {
            ${steps.map((step, i) => `${i * 100 / steps.length}% { transform: rotate(${step}deg); }`).join("")}
          }
        `;
        document.head.appendChild(keyframesStyle);
    }

    function removeKeyframes() {
        if (keyframesStyle) {
            document.head.removeChild(keyframesStyle);
        }
    }

    const allPrize = async () => {
        // Notiflix.Loading.circle();

        try {

            await axios.get(`${baseUrl}/user/all_prizes`).then((response) => {
                const prizes = response?.data.result.map(prizeObj => `N${prizeObj.prizes}`);
                const prizesId = response?.data.result.map(prizeObj => prizeObj.allocating_prize_id);

                setPrize(prizes);
                setprizesId(prizesId);
                setTapToSpin(true);
                Notiflix.Loading.remove();
            }).catch((err) => {

                Notiflix.Loading.remove();
                Notiflix.Notify.info("Please check your internet connection");
                if (err?.response?.status == 401) {
                    serviceFunctions.handleLogout();
                }

                // console.log(err);
            });

        } catch (err) {
            // console.log(err);
            Notiflix.Notify.info("Please check your internet connection");
            Notiflix.Loading.remove();
        }
    }

    useEffect(() => {

        const interval = setTimeout(() => {
            if (token)
                allPrize();
        }, 1000);

        return () => clearInterval(interval);

    }, [token]);

    useEffect(() => {

        const interval = setTimeout(() => {
            if (token) {

                try {

                    Notiflix.Loading.circle();
                    axios.get(`${baseUrl}/user/user_profile`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            setUserdata(response.data.data);
                            setAvailableSpin(response.data.data.available_spins);
                            localStorage.setItem("network", response.data.data.network);
                            Notiflix.Loading.remove();
                        })
                        .catch(error => {

                            Notiflix.Loading.remove();
                            if (error?.response?.status == 401) {
                                serviceFunctions.handleLogout();
                            }

                            console.error("An error occurred while fetching data:", error);
                        });

                } catch (err) {
                    Notiflix.Loading.remove();
                }
            }
        }, 10);

        return () => clearInterval(interval);

    }, [token, user_id, availableSpin]);

    const navigate = useNavigate();

    const wheelRef = useRef(null);
    const spinBtnRef = useRef(null);
    const stopBtnRef = useRef(null);
    const finalValueRef = useRef(null);
    const myChartRef = useRef(null);
    const finalPrizeRef = useRef(null);
    const wonAudioRef = useRef(new Audio("/images/wonaudio.mp3"));
    const [showGif, setShowGif] = useState(false);
    const gifRef = useRef(null);

    const initializeChart = () => {
        const data = [16, 16, 16, 16, 16, 16];
        const pieColors = [
            "#faa629",
            "#e65051",
            "#39a0e8",
            "#2eae74",
            "#c93c77",
            "#b163da",
        ];

        if (myChartRef.current) {
            myChartRef.current.destroy();
        }

        myChartRef.current = new Chart(wheelRef.current, {
            plugins: [ChartDataLabels],
            type: "pie",
            data: {
                labels: [...prize],
                datasets: [
                    {
                        backgroundColor: pieColors,
                        data: data,
                    },
                ],
            },
            options: {
                responsive: true,
                animation: { duration: 0 },
                plugins: {
                    tooltip: false,
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        rotation: function (ctx) {
                            const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
                            const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                            const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum * 360);
                            return rotation < 180 ? rotation - 90 : rotation + 90;
                        },
                        color: "#ffffff",
                        formatter: (_, context) =>
                            context.chart.data.labels[context.dataIndex],

                        textAlign: 'center',

                        font: {
                            weight: 'bold',
                            size: 15,
                        }
                    },
                },
            },
        });
    };

    useEffect(() => {
        initializeChart();
    }, [token, prize]);

    const valueGenerator = (angleValue, targetPrizeId) => {
        let rotationValues = [];

        for (let i = 0; i < prize.length; i++) {
            const minDegree = (i * (360 / prize.length));
            const maxDegree = (((i + 1) * (360 / prize.length)) - 1);
            const value = prize[i];
            const prizeId = prizesId[i];

            rotationValues.push({ minDegree, maxDegree, value, prizeId });
        }

        const wonPrize = rotationValues.filter(el => el.prizeId === targetPrizeId)[0];
        finalValueRef.current.innerHTML = `You Won: ${wonPrize?.value}`;
        finalPrizeRef.current = `${wonPrize?.prizeId}`;

        if (wonPrize?.prizeId > 0) {
            if (sound) {
                wonAudioRef.current.play()
            }
            setShowGif(true); // Show GIF when a prize is won
            setTimeout(() => setShowGif(false), 2500);
        }
    };

    const spinBtnClickHandler = () => {
        if (!tapToSpin) {
            return;
        }
        setIsSpinning(true);
        Notiflix.Loading.circle();
        finalValueRef.current.innerHTML = '';
        axios.post(
            `${baseUrl}/user/spin_the_wheel`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => {
                Notiflix.Loading.remove();
                setAvailableSpin(prevAvailableSpin => prevAvailableSpin - 1);
                if (response.data.success == true) {
                    const toggleAudio = () => {
                        if (isAudioPlaying) {
                            audioRef.current.pause();
                        } else {
                            audioRef.current.play().catch(err => console.log(err));
                            audioRef.current.muted = false; // Unmute the audio
                        }
                        setIsAudioPlaying(isAudioPlaying);
                    };
                    toggleAudio();
                    const targetPrizeId = response?.data?.data?.prize_amount?.prize_id;
                    setPrizeId((prevPrizeId) => targetPrizeId);
                    setTargetRotation(calculateTargetRotation(targetPrizeId));
                    const rotatingdeg = 360 * (20 * rotationCount) + calculateTargetRotation(targetPrizeId);

                    setRotationCount(rotationCount + 1);
                    handleStopSpin(rotatingdeg);

                    const wheel = document.getElementById("wheel");
                    wheel.style.transition = 'transform 7s ease-out';
                    wheel.style.transform = `rotate(${rotatingdeg}deg)`;

                    setTimeout(() => {
                        valueGenerator(calculateTargetRotation(targetPrizeId), targetPrizeId);
                        setIsSpinning(false);
                    }, 7000);
                } else {
                    const toggleAudio = () => {
                        if (isAudioPlaying) {
                            audioRef.current.pause();
                        } else {
                            audioRef.current.play().catch(err => console.log(err));
                            audioRef.current.muted = false; // Unmute the audio
                        }
                        setIsAudioPlaying(isAudioPlaying);
                    };
                    toggleAudio();
                    const targetPrizeId = response.data.prize_id;
                    setPrizeId((prevPrizeId) => targetPrizeId);
                    setTargetRotation(calculateTargetRotation(targetPrizeId));
                    const rotatingdeg = 360 * (20 * rotationCount) + calculateTargetRotation(targetPrizeId);

                    setRotationCount(rotationCount + 1);
                    handleStopSpin(rotatingdeg);

                    const wheel = document.getElementById("wheel");
                    wheel.style.transition = 'transform 7s ease-out';
                    wheel.style.transform = `rotate(${rotatingdeg}deg)`;

                    setTimeout(() => {
                        valueGenerator(calculateTargetRotation(targetPrizeId), targetPrizeId);
                        setIsSpinning(false);
                    }, 7000);
                }
            })
            .catch((error) => {
                Notiflix.Loading.remove();
                if (error?.response?.status == 401) {
                    serviceFunctions.handleLogout();
                }


                toast.error(error.response.data.message, 3000)
                setIsSpinning(false);
            });
    };

    const calculateTargetRotation = (targetPrizeId) => {
        const minValue = 5;
        const maxValue = 30;
        const randomValue = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
        const index = prizesId.indexOf(targetPrizeId);
        const targetRotation = 360 - (60 * (index + 1)) + randomValue;
        return targetRotation;
    };

    const handleStopSpin = (degrees) => {
        if (isSpinning) {

            if (!showCountdown) {
                startCountdown(7);
                setBtnDisable(true);
            }

            const wheel = document.getElementById("wheel");
            wheel.style.transition = 'none';
            wheel.style.transform = `rotate(${degrees}deg)`;
            valueGenerator(targetRotation, prizeId);
            setIsSpinning(false);

            const toggleAudio = () => {
                if (isAudioPlaying) {
                    audioRef.current.pause();
                } else {
                    audioRef.current.play().catch(err => console.log(err));
                    audioRef.current.muted = true; // Unmute the audio
                }
                setIsAudioPlaying(isAudioPlaying);
            };
            toggleAudio();
        }
    };


    const handleSound = () => {

        if (!isSpinning) {
            setSound(!sound);
            setIsAudioPlaying(!isAudioPlaying);
            localStorage.setItem('sound', !sound);
        }

    }
    return (
        <>
            <div className="spinWheel">
                <Toaster />
                {showGif && (
                    <div className="winnerGif">
                        <img ref={gifRef} src="/images/winning.gif" alt="Winning GIF" />
                    </div>
                )}
                <div className="wallet d-flex justify-content-center">
                    <div className={`profileName ${(isSpinning || btnDisable) ? 'unclickable' : ''}`} onClick={() => !(isSpinning || btnDisable) && navigate('/profile')}>
                        <img
                            src={userData.image ? userData.image : "images/avatar.png"}
                            className={`profile `}

                            alt={"myimage"}
                        />
                        <small>Profile</small>
                    </div>
                    <div className="gamelogo">
                        <img src="images/logoimg.png" alt={"gamelogo"} />
                    </div>
                    <div onClick={() => !(isSpinning || btnDisable) && navigate('/help')} className={`spinhelp ${(isSpinning || btnDisable) ? 'unclickable' : ''}`}>
                        <FcInfo />
                        <small>Info</small>
                    </div>
                </div>


                <div className="content spin-wheel-myrow">
                    <div className={`logobox ${(isSpinning || btnDisable) ? 'unclickable' : ''}`} onClick={handleSound}>
                        {
                            sound ? <img src="images/audio.png" alt={"audio-on"} /> : <img src="images/audio-off.png" alt={"audio-off"} />
                        }
                        <small>Sound</small>

                    </div>
                    <div className="winnerPrize">
                        <strong><i><span ref={finalValueRef}></span></i></strong>
                    </div>
                    <div className={`wallet_name ${(isSpinning || btnDisable) ? 'unclickable' : ''}`}
                        onClick={() => !(isSpinning || btnDisable) && navigate('/my-wallet')}>
                        <img
                            src="images/wallet.gif"
                            className="walletimg"

                        />
                        <small>Cashout</small>
                    </div>
                </div>

                <div className="fullwheel mb-2">
                    <div className="containers">
                        <div className="box mc-rotate-box">
                            <canvas ref={wheelRef} id="wheel"></canvas>

                            <img src="images/spinner.png" alt="spinner" className="spinners" />
                            <img src="images/wheelwidth.png" alt="wheelouter" className="wheelouter" />
                        </div>
                    </div>
                </div>
                <div className="spinscount">
                    <p>Available Spin: {availableSpin ? availableSpin : '0'}</p>
                </div>
                <div className="spinWheelBtn">
                    {showCountdown && (
                        <div className="text-center">
                            Next Spin: 00:0{countdown}
                        </div>
                    )}

                    <button ref={spinBtnRef} value="Tap to Spin" className={`btn btn-info mb-1 tapping ${!tapToSpin ? `btn-tap-disable` : ``}`}
                        onClick={spinBtnClickHandler} disabled={!tapToSpin}>
                        Tap to Spin
                    </button>

                    <button
                        value="Stop Spin"
                        className="btn btn-warning mb-1 tapping"
                        ref={stopBtnRef}
                        disabled={!isSpinning}
                        onClick={() => handleStopSpin(targetRotation)}
                    >
                        Stop Spin
                    </button>
                    <Buttons
                        value="Buy more spin"
                        className={` btn btn-success mb-3 ${(isSpinning || btnDisable) ? 'unclickable' : ''}`}
                        onClick={() => navigate("/buy-spins")}
                    />
                </div>
            </div>
        </>
    );
}
