import React, { useEffect } from 'react';
import Notiflix from 'notiflix';
import { baseUrl } from '../constant';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import serviceFunctions from '../Services';

const Confirmation = () => {

    let navigate = useNavigate();

    useEffect(() => {
        Notiflix.Loading.init({
            svgSize: '60px',
            svgColor: '#ffffff',
            backgroundColor: 'rgba(0,0,0,0.7)'
        });

        Notiflix.Loading.standard({
            clickToClose: false,
            rtl: false
        });
    }, []);

    useEffect(() => {
        const interval = setTimeout(() => {

            Notiflix.Loading.remove();
            navigate("/");

        }
            , 1000 * 60 * 5);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {

        let subscription_id = localStorage.getItem("subscription_id");

        if (!subscription_id) {
            navigate("/spin-wheel");
        }

        const interval = setInterval(() => {

            (async () => {

                try {

                    let response = await axios.get(`${baseUrl}/user/user-subscribed-status/${subscription_id}`);

                    if (response.status === 200) {

                        if (response.data.data.confirmed) {
                            Notiflix.Loading.remove(); // Close the loader
                            navigate("/spin-wheel");
                        } else {
                            // console.log('do nothing');
                        }

                    }

                } catch (err) {

                    if (err?.response?.status == 401) {
                        serviceFunctions.handleLogout();
                    }

                    console.error('Error checking subscription status:', err);
                }

            })();

        }, 3000);

        return () => clearInterval(interval);
    }, [navigate]);

    return (
        <div className="loader-container">
            <h1>Please wait...</h1>
            <div id="loader" />
        </div>
    );
};

export default Confirmation;
