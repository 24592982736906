import React from 'react';
import { useNavigate } from "react-router-dom";
import Buttons from "../Components/Buttons";
import { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import axios from "axios";
import { baseUrl } from "../constant";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Notiflix from 'notiflix';
const Home = () => {
  const [prize, setPrize] = useState([])
  const [prizesId, setprizesId] = useState(null)
  const navigate = useNavigate();
  const Subscribe = () => {
    Notiflix.Loading.circle()
    setTimeout(() => {
      navigate("/subscription");
      Notiflix.Loading.remove();
    }, 500);

  };
  const Login = () => {
    Notiflix.Loading.circle()
    setTimeout(() => {
      navigate("/login");
      Notiflix.Loading.remove();
    }, 500);

  };

  const Trial = () => {
    Notiflix.Loading.circle()
    setTimeout(() => {
      navigate("/user-trial");
      Notiflix.Loading.remove();
    }, 500);

  };
  Notiflix.Loading.init({
    backgroundColor: 'rgba(0,0,0,0.5)',
    svgColor: '#73ddff ',
    clickToClose: false,
  });

  const wheelRef = useRef(null);
  const myChartRef = useRef(null);

  const allPrize = async () => {

    try {

      Notiflix.Loading.circle();

      await axios.get(`${baseUrl}/user/all_prizes`).then((response) => {

        const prizes = response?.data.result.map(prizeObj => `N${prizeObj.prizes}`);
        const prizesId = response?.data.result.map(prizeObj => prizeObj.id);

        setPrize(prizes)
        setprizesId(prizesId)

        Notiflix.Loading.remove();

      }).catch((err) => {

        Notiflix.Loading.remove();

      })
    } catch (err) {
      Notiflix.Loading.remove();
      return err.message;
    }

  }

  useEffect(() => {
    allPrize();
  }, [])

  useEffect(() => {
    const data = [16, 16, 16, 16, 16, 16];
    const pieColors = [
      "#faa629",
      "#e65051",
      "#39a0e8",
      "#2eae74",
      "#c93c77",
      "#b163da",
    ];




    myChartRef.current = new Chart(wheelRef.current, {
      plugins: [ChartDataLabels],
      type: "pie",
      data: {
        labels: [...prize],
        datasets: [
          {
            backgroundColor: pieColors,
            data: data,
          },
        ],
      },
      options: {
        responsive: true,
        animation: { duration: 0 },
        plugins: {
          tooltip: false,
          legend: {
            display: false,
          },
          datalabels: {
            rotation: function (ctx) {
              const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
              const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
              const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum * 360);
              return rotation < 180 ? rotation - 90 : rotation + 90;
            },
            color: "#ffffff",
            formatter: (_, context) =>
              context.chart.data.labels[context.dataIndex],
            textAlign: 'center',
            font: {
              weight: 'bold',
              size: 15,
            }
          },
        },
      },
    });

    return () => {
      myChartRef.current.destroy();
    };
  }, [prize]);

  return (
    <>
      <div className="firstScreen">
        <div className="gamelogo">
          <img src="images/logoimg.png" alt={"gamelogo"} />
          <span className='help'>
            <span onClick={() => navigate("/help")} > Help</span>
          </span>
        </div>

        <div className="content">
          <p>
            Welcome to{" "}
            <b>
              <i>Spin4cash</i>
            </b>
          </p>
          <p>Take a Chance, Give it a spin!!!</p>
        </div>
        <div className="fullwheel text-center">
          <div className="containers">
            <div className="box">
              <canvas ref={wheelRef} id="wheel"></canvas>

              <img src="images/spinner.png" alt="spinner" className="spinners" />
              <img src="images/wheelwidth.png" alt="wheelouter" className="wheelouter" />
            </div>
          </div>
        </div>
        <div className="homeButton ">
          {/* <Buttons
            value="Register"
            onClick={Subscribe}
            className="btn btn-warning mb-1 resgister" /> */}

          <Buttons
            value="Login"
            onClick={Login}
            className="btn btn-success mb-1"
          />

          <Buttons
            value="Trial Spin"
            className="btn btn-info"
            onClick={Trial}
          />

        </div>
      </div >
    </>
  );
};

export default Home;
