import React from 'react';
import { useState, useEffect } from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Buttons from "../Components/Buttons";
import toast, { Toaster } from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { useFormik } from 'formik';
import axios from "axios";
import { baseUrl } from '../constant';
import Notiflix from 'notiflix';
import serviceFunctions from '../Services';
import { CiLock } from 'react-icons/ci';
import { ValidatePinReset } from '../Components/Yup';
import { CiUser } from "react-icons/ci";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export default function Verification() {

    const [isSubmit, setIsSubmit] = useState(false);

    const [pinReset, setPinReset] = useState(false);

    const navigate = useNavigate();

    const mobileNumber = localStorage.getItem('mobile');

    const query = useQuery();

    useEffect(() => {

        if (query.get('pin_reset')) {
            setPinReset(true);
        }

    }, [])

    useEffect(() => {

        // console.log({ pinReset });

    }, [pinReset]);


    const formInitialValues = {
        otp: '',
        ...(pinReset ? {
            enter_pin: "",
            verify_pin: "",
            user_name: ""
        } : {})
    };


    const [countdown, setCountdown] = useState(20);
    const [showResendButton, setShowResendButton] = useState(false);

    const { handleChange, values, handleSubmit, errors, handleBlur } = useFormik({

        initialValues: formInitialValues,
        ...(pinReset ? { validationSchema: ValidatePinReset } : {}),
        onSubmit: async (values) => {


            Notiflix.Loading.circle();

            await axios.post(`${baseUrl}/user/mobile-otp-verify`, {
                mobile: mobileNumber,
                otp: values.otp,
                ...values
            }).then(response => {

                if (response.data.success) {
                    setIsSubmit(true)
                    toast.success(response.data.message);
                    setTimeout(() => navigate('/spin-wheel'), 3000);
                    localStorage.setItem('token', response.data.accessToken);
                } else {
                    setIsSubmit(false)
                    toast.error(response.data.message, { duration: 4000 })
                }
                Notiflix.Loading.remove();
            })
                .catch(error => {

                    // if (error?.response?.status == 401) {
                    //     serviceFunctions.handleLogout();
                    // }

                    toast.error(error.response.data.message, { duration: 4000 })
                    console.error(error);
                    Notiflix.Loading.remove();
                });
        }
    });

    Notiflix.Loading.init({
        backgroundColor: 'rgba(0,0,0,0.5)',
        svgColor: '#73ddff ',
        clickToClose: false,
    });



    useEffect(() => {

        let interval;

        const updateCountdown = () => {
            setCountdown(prevCountdown => {
                if (prevCountdown > 0) {
                    return prevCountdown - 1;
                } else {
                    clearInterval(interval);
                    setShowResendButton(true);
                    return 0;
                }
            });
        };

        interval = setInterval(updateCountdown, 1000);

        // Cleanup the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    const handleResendClick = async () => {

        // Reset countdown and hide the resend button
        try {

            const response = await axios.post(`${baseUrl}/user/resendOtp`,
                {
                    mobile: mobileNumber,
                }
            );

            if (response.data.success) {
                toast.success(response.data.message, { duration: 4000 });

            } else {
                toast.error(response.data.message, { duration: 4000 });
            }
        } catch (error) {

            if (error?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            toast.error("An error occurred. Please try again.");
            console.error(error);
        }
        setCountdown(20);
        setShowResendButton(false);
        // Restart the countdown
        let interval = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown > 0) {
                    return prevCountdown - 1;
                } else {
                    clearInterval(interval);
                    setShowResendButton(true);
                    return 0;
                }
            });
        }, 1000);
    };

    return (
        <div className="verification subscribe">
            <Toaster />
            <div className="navigater">
                <FaArrowLeftLong onClick={() => navigate(-1)} />
                <p onClick={() => navigate("/help")}>Help</p>
            </div>

            <div className="mc-note">

                <h2><strong>NOTE</strong></h2>

                <p>
                    You need an active subscription to receive OTP for PIN reset.
                    Click How to subscribe for information.
                </p>

            </div>

            <div className='mc-get-my-pin-container'>
                <button className='mc-get-my-pin' onClick={() => navigate("/plans")}>How to Subscribe</button>
            </div>

            <div className='dashed-line'></div>


            <div className="content">
                <span>ENTER OTP SENT TO {mobileNumber}</span>
            </div>


            <div className="getOtp">



                <form className='w-100' onSubmit={handleSubmit}>

                    <div className='mc-otp-input'>

                        <OtpInput
                            value={values.otp}
                            onChange={(otp) => handleChange('otp')(otp)}
                            numInputs={4}
                            name='otp'
                            renderInput={(props) => <input {...props} type="number" />}
                        />

                        {errors.otp ? (
                            <span style={{ color: "red" }}>{errors.otp}</span>
                        ) : null}

                    </div>
                    {pinReset && <>


                        <div className='mc-new-pin'>
                            <h2>Enter new PIN</h2>
                        </div>

                        <div className="formSpace mt-2">
                            <div className="icon">
                                <CiLock />
                            </div>
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="Enter PIN"
                                    name="enter_pin"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.enter_pin}
                                />
                                <label htmlFor="name">Enter 4 Digit PIN</label>
                            </div>
                        </div>
                        {errors.enter_pin ? (
                            <span style={{ color: "red" }}>{errors.enter_pin}</span>
                        ) : null}

                        <div className="formSpace mt-2">
                            <div className="icon">
                                <CiLock />
                            </div>
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="Verify PIN"
                                    name="verify_pin"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.verify_pin}
                                />
                                <label htmlFor="name">Verify 4 Digit PIN</label>
                            </div>
                        </div>
                        {errors.verify_pin ? (
                            <span style={{ color: "red" }}>{errors.verify_pin}</span>
                        ) : null}


                        <div className="formSpace mt-2">
                            <div className="icon">
                                <CiUser />
                            </div>
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="Enter Name"
                                    name="user_name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.user_name}
                                />
                                <label htmlFor="name">Enter You Name</label>
                            </div>
                        </div>
                        {errors.user_name ? (
                            <span style={{ color: "red" }}>{errors.user_name}</span>
                        ) : null}

                    </>}
                    <Buttons
                        value='Submit'
                        className='btn btn-info mt-2'
                        type="submit"
                        disabled={isSubmit}
                    />

                </form>
            </div>

            <div className="newCode">
                {showResendButton ? (
                    <Buttons
                        value='Resend'
                        className='btn btn-warning mt-2 resendButton'
                        onClick={handleResendClick}
                    />
                ) : (
                    countdown > 0 ? `0:${countdown < 10 ? `0${countdown}` : countdown}` : '0:00'
                )}
            </div>
        </div>
    );
}
