import axios from 'axios';
import Notiflix from 'notiflix';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../constant';
import { v4 as uuidv4 } from 'uuid';

const generateUuid = () => {
    return uuidv4();
};

export default function MtnProceed() {

    let navigate = useNavigate();

    return (
        <>
            <div className='mc-page-container'>
                <header className='mc-game-header'>
                    <img src='images/logoimg.png' alt='' className='mc-logo' />
                </header>
                <section className='mc-section-proceed'>

                    {/* <div>
                        <h3>Welcome</h3>
                        <h2>{mobile && mobile}</h2>
                    </div> */}
                    <div className='logo-container'>
                        <img src='images/wheellogo.png' alt='' />
                    </div>

                    <div className='btn-container'>
                        <button
                            className='mc-proceed-btn'

                        ><a style={{ textDecoration: "none", color: "black" }} href={`https://ng-app.com/NinaJojer/spin4cash-on-demand-landing-en-doi-web?origin_banner=3&trxId=${generateUuid()}&trfsrc=secureD`}>Click to Proceed </a></button>
                    </div>
                </section>
            </div>
        </>
    )
}
