// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family:'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
    display: flex;
    justify-content: center;
    height: 100vh;
   
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  background-color: #F4FEFF;
width:30%
}
.buttons{
  width: 100%;
  padding: 16px 0;
  border-radius: 8px;
  border: none;
  color: #212121;
  font-size: 16px;
  font-weight: 500
}

 .btn-success {
  background-color: #109e38 !important;
}

.btn-success:hover {
  background-color: #01581a !important;
  color:#fff!important
}
.btn-success.submitmail {
  color: #fff!important;
}
.btn-warning {
  background-color: #ffad4d !important;
}
.btn-warning.resgister{
  background-color: #FFD345 !important;
}
.btn-warning.resgister:hover{
  background-color: #ffc403 !important;
  color: #fff!important;
}
.btn-info {
  background-color: #73ddff !important;
}
.btn-info:hover{
  background-color: #39A0E8 !important;
  color: #fff!important;
}
.btn:hover{
  color: #212121;
}

@media only screen and (max-width: 576px) {
  body{
    display: unset;
    height: unset;
    background-color: #F4FEFF;
  
  }
  #root{
    width:100%
  }
  
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,qBAAqB;EACrB,mCAAmC;EACnC,kCAAkC;;IAEhC,aAAa;IACb,uBAAuB;IACvB,aAAa;;AAEjB;;AAEA;EACE;aACW;AACb;AACA;EACE,yBAAyB;AAC3B;AACA;AACA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,eAAe;EACf;AACF;;CAEC;EACC,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC;AACF;AACA;EACE,qBAAqB;AACvB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;EACpC,qBAAqB;AACvB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;EACpC,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,cAAc;IACd,aAAa;IACb,yBAAyB;;EAE3B;EACA;IACE;EACF;;AAEF","sourcesContent":["body {\n  margin: 0;\n  font-family:'Poppins';\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  \n    display: flex;\n    justify-content: center;\n    height: 100vh;\n   \n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n#root{\n  background-color: #F4FEFF;\nwidth:30%\n}\n.buttons{\n  width: 100%;\n  padding: 16px 0;\n  border-radius: 8px;\n  border: none;\n  color: #212121;\n  font-size: 16px;\n  font-weight: 500\n}\n\n .btn-success {\n  background-color: #109e38 !important;\n}\n\n.btn-success:hover {\n  background-color: #01581a !important;\n  color:#fff!important\n}\n.btn-success.submitmail {\n  color: #fff!important;\n}\n.btn-warning {\n  background-color: #ffad4d !important;\n}\n.btn-warning.resgister{\n  background-color: #FFD345 !important;\n}\n.btn-warning.resgister:hover{\n  background-color: #ffc403 !important;\n  color: #fff!important;\n}\n.btn-info {\n  background-color: #73ddff !important;\n}\n.btn-info:hover{\n  background-color: #39A0E8 !important;\n  color: #fff!important;\n}\n.btn:hover{\n  color: #212121;\n}\n\n@media only screen and (max-width: 576px) {\n  body{\n    display: unset;\n    height: unset;\n    background-color: #F4FEFF;\n  \n  }\n  #root{\n    width:100%\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
