import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import Buttons from "../Components/Buttons";
import { useFormik } from "formik";
import axios from "axios";
import { baseUrl } from "../constant";
import toast, { Toaster } from 'react-hot-toast';
import { Verifications } from '../Components/Yup';
import { TbCurrencyNaira } from "react-icons/tb";
import Notiflix from 'notiflix';
import serviceFunctions from '../Services';
export default function WithdrawAmount() {



    const [wallet, setWallet] = useState('');
    const [bankDetails, setBankDetail] = useState('');

    const [submitBtn, setSubmitBtn] = useState(true);
    const currencyFormat = wallet?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    const navigate = useNavigate();
    let token = localStorage.getItem("token");

    const formInitialValues = {
        withdraw: "",
    };
    const { handleChange, values, errors, touched, handleBlur, handleSubmit } = useFormik({
        initialValues: formInitialValues,
        validationSchema: Verifications,
        onSubmit: async (values) => {

            try {
                if (parseFloat(values.withdraw) > parseFloat(wallet)) {
                    toast.error("Withdraw amount cannot be more than the available balance.", { duration: 4000 })

                    return;
                }
                if (submitBtn) {

                    setSubmitBtn(false);
                    Notiflix.Loading.circle()
                    const response = await axios.post(
                        `${baseUrl}/user/initiate_transfer`,
                        {
                            amount: values.withdraw
                        },
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        }
                    );

                    // console.log("API Response:", response);

                    if (response.data.success) {
                        toast.success(response.data.message, { duration: 3000 })
                        setTimeout(() => navigate('/congrats'), 3000);
                        Notiflix.Loading.remove();
                    } else {
                        toast.error(response.data.message, { duration: 4000 })
                        Notiflix.Loading.remove();
                    }

                    setSubmitBtn(true);
                }
            } catch (error) {

                if (error?.response?.status == 401) {
                    serviceFunctions.handleLogout();
                }

                setSubmitBtn(true);

                toast.error(error.response.data.message, { duration: 4000 });
                console.error("API Error:", error);
                Notiflix.Loading.remove();
            }
        },

    })
    const fetchData = async () => {
        Notiflix.Loading.circle()
        try {
            const response = await axios.get(`${baseUrl}/user/wallet`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            Notiflix.Loading.remove();
            setWallet(response.data.data);
        } catch (error) {
            Notiflix.Loading.remove();
            console.error("Error fetching data:", error.message);
        }
    };

    const fetchBankDetails = async () => {
        Notiflix.Loading.circle()
        try {
            const response = await axios.get(`${baseUrl}/user/bank_details`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            // console.log('Data:', response.data.data);
            setBankDetail(response.data.data);
            Notiflix.Loading.remove();
        } catch (error) {

            if (error?.response?.status == 401) {
                serviceFunctions.handleLogout();
            }

            console.error("Error fetching data:", error.message);
            Notiflix.Loading.remove();
        }
    };
    useEffect(() => {
        fetchData();
        fetchBankDetails();

    }, [])
    return (
        <div className='WithdrawAmount'>
            <Toaster />
            <FaArrowLeftLong onClick={() => navigate(-1)} className='close' />
            <div className="mt-2 title">
                <p>Withdraw</p>
            </div>
            <div className="addnote">
                <p>Withdrawal will be to-
                </p>
                <div className="banknote mt-2">
                    <p><strong>Bank Name:</strong> {bankDetails.bank_name} </p>
                    <p><strong>Account No:</strong> {bankDetails.account_name}</p>
                    <p><strong>Account Name:</strong> {bankDetails.account_number}</p>
                </div>

            </div>
            <div className="mb-2 mt-3 withdraw-verification">
                <label className="form-label">Available Balance</label>
                <div className='totalAmount'>
                    <TbCurrencyNaira />
                    <input type="text" className="form-control" name="balance" value={currencyFormat ? currencyFormat : 0} readOnly />
                </div>


            </div>

            <form onSubmit={handleSubmit} autoComplete="off">

                <div className="mb-2">
                    <label className="form-label">Enter Amount to transfer</label>
                    <div className='totalAmount'>
                        <TbCurrencyNaira />
                        <input
                            type="number"
                            className="form-control"
                            name="withdraw"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.withdraw}

                        />
                    </div>


                </div>
                {errors.withdraw && touched.withdraw ? (
                    <span style={{ color: "red" }}>{errors.withdraw}</span>
                ) : null}
                <div className="d-flex justify-content-between buttons">

                    <Buttons
                        value="Submit"
                        className={`btn btn-warning ${submitBtn ? `` : `btn-disabled`}`}
                        type="submit"
                    />

                    <Buttons
                        value="Withdraw history"
                        className="btn btn-info "
                        onClick={() => navigate('/withdraw-history')}
                    />

                </div>
            </form>


        </div>
    )
}
